import React from "react";
import {NavLink, Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logout, reset} from "../../features/auth/authSlice";

// IMAGE IMPORT
import homeIcon from "../../images/home-icon-white.svg";
import profileIcon from "../../images/profile-icon-white.svg";
import watermeterIcon from "../../images/watermeter-icon-white.svg";
import logo from "../../images/logo.svg";
import logoutIcon from "../../images/logout-icon-white.svg";
import buildingIcon from "../../images/building-icon-white.svg"
import usersIcon from '../../images/users-icon-white.svg'
import fileZipper from '../../images/file-zipper-white.svg'

export default function MenuNav() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleLogout = () => {
		dispatch(logout());
		dispatch(reset());
		navigate("/");
	};

	const {userInfo} = useSelector((state) => state.auth);

	return (
		<>
			<div className="option-bar-container">
				<div className="logo-container">
					<img className="logo" src={logo} />
				</div>
				<div className="options-container">
					{userInfo?.user_type === "client" ? (
						<>
							<NavLink to="/menu" end className={"option-container"} activeclass="active">
								<img src={homeIcon} className="option-icon" />
								<h3 className="option-text">Inicio</h3>
							</NavLink>
							<NavLink to="/menu/profile" className={"option-container"} activeclass="active">
								<img src={profileIcon} className="option-icon" />
								<h3 className="option-text">Mi perfil</h3>
							</NavLink>
							<NavLink to="/menu/watermeter" className={"option-container"} activeclass="active">
								<img src={watermeterIcon} className="option-icon" />
								<h3 className="option-text">Estado Medidor</h3>
							</NavLink>
						</>
					) : userInfo?.user_type === "admin_apr" ? (
						<>
							<NavLink to="/menu_apr" end className={"option-container"} activeclass="active">
								<img src={homeIcon} className="option-icon" />
								<h3 className="option-text">Inicio</h3>
							</NavLink>
							<NavLink to="/menu_apr/profile" className={"option-container"} activeclass="active">
								<img src={buildingIcon} className="option-icon" />
								<h3 className="option-text">Perfil APR</h3>
							</NavLink>
							<NavLink to="/menu_apr/watermeters_list" className={"option-container"} activeclass="active">
								<img src={watermeterIcon} className="option-icon" />
								<h3 className="option-text">Estados Medidores</h3>
							</NavLink>
							<NavLink to="/menu_apr/users_list" className={"option-container"} activeclass="active">
								<img src={usersIcon} className="option-icon" />
								<h3 className="option-text">Usuarios</h3>
							</NavLink>
							<NavLink to="/menu_apr/file_api" className={"option-container"} activeclass="active">
								<img src={fileZipper} className="option-icon" />
								<h3 className="option-text">Importar/Exportar archivos</h3>
							</NavLink>
						</>
					) : (
						""
					)}
				</div>
				<Link className="logout-container" to="/" onClick={handleLogout}>
					<img className="logout-icon" src={logoutIcon} />
					<h3 className="logout-text">Cerrar Sesión</h3>
				</Link>
			</div>
		</>
	);
}
