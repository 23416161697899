import {BrowserRouter as Router, Routes, Route, Outlet} from "react-router-dom";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import NotFound from "./components/NotFound";
import Resources from "./components/Resources";
import Login from "./components/Login";
import Solutions from "./components/Solutions";

import "./App.scss";
import MenuUser from "./components/menu/MenuUser";
import {ToastContainer} from "react-toastify";
import MenuNav from "./components/menu/MenuNav";
import WatermeterList from "./components/menu/WatermetersList";
import MenuApr from "./components/menu/MenuApr";
import Profile from "./components/menu/Profile";
import ProfileApr from "./components/menu/ProfileApr";
import Watermeter from "./components/menu/Watermeter";
import UserLists from "./components/menu/UsersLists";
import EditUser from "./components/menu/EditUser";
import AddLecture from "./components/menu/AddLecture";
import Consumptions from "./components/menu/Consumptions";
import FileApi from "./components/menu/FileApi";

function App() {
	return (
		<>
			<Router>
				<Routes>
					<Route path="/" element={<WithNavAndFooter />}>
						<Route index element={<Home />} />
						<Route path="/solutions" element={<Solutions />} />
						<Route path="/about" element={<About />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/resources" element={<Resources />} />
						<Route path="/login" element={<Login />} />
						<Route path="*" element={<NotFound />} />
					</Route>

					<Route path="/menu" element={<WithoutNavAndFooter />}>
						<Route index element={<MenuUser />} />
						<Route path='/menu/profile' element={<Profile />}/>
						<Route path='/menu/watermeter' element={<Watermeter />}/>
					</Route>

					<Route path="/menu_apr" element={<WithoutNavAndFooter />}>
						<Route index element={<MenuApr />} />
						<Route path='/menu_apr/profile' element={<ProfileApr />}/>
						<Route path='/menu_apr/watermeters_list' element={<WatermeterList />}/>
						<Route path='/menu_apr/users_list' element={<UserLists />}/>
						<Route path='/menu_apr/file_api' element={<FileApi />}/>
						<Route path="/menu_apr/editar-usuario/:userId" element={<EditUser />} />
						<Route path="/menu_apr/consumptions/:userId" element={<Consumptions />} />
						<Route path="/menu_apr/agregar-lectura/:meterId" element={<AddLecture />} />
					</Route>
				</Routes>
			</Router>
			<ToastContainer />
		</>
	);
}

function WithNavAndFooter() {
	return (
		<>
			<div className="main">
				<Nav />
				<Outlet /> {/* Aquí se renderizarán los elementos hijos de las rutas */}
				<Footer />
			</div>
		</>
	);
}

function WithoutNavAndFooter() {
	return (
		<>
			<div className="background-overlap" />
			<div className="main-menu">
				<MenuNav />
				<Outlet />
			</div>
		</>
	);
}

export default App;
