import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {BiLogInCircle} from "react-icons/bi";
import {getUserInfo, login, reset} from "../features/auth/authSlice";
import {toast} from "react-toastify";
import Spinner from "./Spinner";

import "../stylesheets/login.scss";

export default function Login() {
	const [formData, setFormData] = useState({
		rut: "",
		password: "",
	});

	const {rut, password} = formData;

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {user, isLoading, isError, isSuccess, message, userInfo} = useSelector((state) => state.auth);

	const [userType, setUserType] = useState(null);
	// console.log("userType", userInfo)
	// console.log("state.isSuccess", isSuccess);

	const handleChange = (e) => {
		setFormData((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const userData = {
			rut,
			password,
		};
		return dispatch(login(userData));
	};

	useEffect(() => {
		// console.log("useEffect")
		dispatch(reset());
		dispatch(getUserInfo());
	}, [dispatch]);

	useEffect(() => {
		// console.log("isSuccess", isSuccess);
		// console.log("userInfo?.user_type", userInfo?.user_type);

		if (isSuccess) {
			dispatch(getUserInfo()).then(() => {
				if (userInfo?.user_type === "client") {
					navigate("/menu");
				} else if (userInfo?.user_type === "admin_apr") {
					navigate("/menu_apr");
				}
			});
		} else if (isError) {
			toast.error(message);
		}
	}, [isError, isSuccess, userInfo, navigate, message]);

	// useEffect(() => {
	// 	setUserType(userInfo?.user_type)
	// },[userInfo])

	return (
		<>
			<div className="background-overlap" />
			<div className="auth-container">
				<h1 className="main-title">Iniciar Sesión</h1>

				{isLoading && <Spinner />}

				<form className="auth-form">
					<div className="input-container">
						<h2 className="input-title">Rut</h2>
						<input
							className="input-box"
							type="text"
							placeholder="Introduce tu numero de rut. Ej:11.111.111-1"
							name="rut"
							onChange={handleChange}
							value={rut}
							required
						/>
					</div>
					<div className="input-container">
						<h2 className="input-title">Contraseña</h2>
						<input
							className="input-box"
							type="password"
							placeholder="Introduce tu contraseña"
							name="password"
							onChange={handleChange}
							value={password}
							required
						/>
					</div>
					{/* <Link to="/reset-password">Forget Password ?</Link> */}

					<div className="bottom-container">
						<button className="btn-primary" type="submit" onClick={handleSubmit}>
							Iniciar Sesión
						</button>
						<Link to="/reset-password" className="forget-password-link">
							¿Olvidaste tu contraseña?
						</Link>
					</div>
				</form>
			</div>
		</>
	);
}
